import "./index.css"
import { Link } from "react-router-dom"
import Game from "./Game"
import { useEffect, useRef, useState } from "react"
import { FaRegPauseCircle, FaRegPlayCircle, FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight } from "react-icons/fa"
import AudioSpectrum from "./AudioSpectrum"

function Home() {
    // GET INNER WIDTH OF BROWSER //
    const [windowWidth, setWindowWidth] = useState(0)
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }
        handleResize() // Set the initial window width
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    // MUSIC PLAYER LOGIC //
    const [songNumber, setSongNumber] = useState(0)
    const [isMuted, setIsMuted] = useState(true)
    const audioRef = useRef()
    const [isPlaying, setIsPlaying] = useState(false)
    const songList = ["Papa Roach - Blood Brothers.mp3", "Bad religion - you.mp3", "Dub Pistols - Cyclone.mp3", "Lagwagon - May 16.mp3", "Heavy Metal Winner - Consumed.mp3"]

    const handleNextSong = () => {
        if (songNumber + 1 < songList.length) {
            setSongNumber(songNumber + 1)
        } else {
            return
        }
        setIsPlaying(false)
        setTimeout(() => {
            setIsPlaying(true)
        }, 300)
    }
    const handlePrevSong = () => {
        if (songNumber === 0) {
            return
        } else {
            setSongNumber(songNumber - 1)
            setIsPlaying(false)
            setTimeout(() => {
                setIsPlaying(true)
            }, 300)
        }
    }

    useEffect(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.play()
            } else {
                audioRef.current.pause()
            }
        }
    }, [isPlaying])

    const togglePlaying = () => {
        setIsPlaying(!isPlaying)
    }
    useEffect(() => {
        console.log(isPlaying)
    }, [isPlaying])
    // MUSIC PLAYER LOGIC ENDS HERE //

    const [disclaimer, setDisclaimer] = useState(false)
    function handleDisclaimer() {
        setDisclaimer(!disclaimer)
    }

    return (
        <div className="w-screen h-screen relative overflow-hidden flex flex-col justify-center items-center">
            <div
                className={`text-white bg-black p-10 rounded-xl z-20 font-omega absolute w-2/3 md:w-1/2 text-xl text-center duration-150 ease-in-out justify-center flex items-center flex-col ${
                    disclaimer ? " scale-100" : " scale-0"
                }`}
            >
                <p>
                    Disclaimer: Totally NOT associated with Activitision or Tony Hawk. $THPS2 is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or
                    roadmap. <br />
                    The coin is completely useless and for entertainment purposes only.
                </p>
            </div>
            <a onClick={handleDisclaimer}>
                <img
                    className={`h-8 md:h-8 z-20 hover:opacity-70 absolute right-0 rotate-90 duration-150 ease-in-out cursor-pointer ${disclaimer ? "-rotate-90" : "rotate-90"} `}
                    alt=""
                    src="/arrow.webp"
                ></img>
            </a>
            <div className="pt-4 pl-0 absolute top-0 left-0">
                <a href="https://raydium.io/swap/?outputCurrency=EBrMAYymNrAkgmmEkeZ23dhKe6oAT5ZwMXPPMEFDSDUp">
                    <img className="h-8 md:h-14 hover:opacity-70" alt="" src="/buy.webp"></img>
                </a>
                <a href="https://dexscreener.com/solana/EBrMAYymNrAkgmmEkeZ23dhKe6oAT5ZwMXPPMEFDSDUp">
                    <img className="h-8 md:h-14 mt-2 hover:opacity-70" alt="" src="/chart.webp"></img>
                </a>
                <Link to="/tokenomics">
                    <img className="h-8 md:h-14 mt-2 hover:opacity-70" alt="" src="/tokenomics.webp"></img>
                </Link>
                <a href="https://t.me/THPS2">
                    <img className="h-8 md:h-14 mt-2 hover:opacity-70" alt="" src="/telegram.webp"></img>
                </a>
                <a href="https://twitter.com/thps2sol">
                    <img className="h-8 md:h-14 mt-2 hover:opacity-70" alt="" src="/twitter.webp"></img>
                </a>
            </div>
            <Link to="/game" className="w-full flex justify-center -translate-y-9">
                <img alt="" src="/play-button.webp" className="w-2/3 md:w-auto cursor-pointer duration-150 ease-in-out hover:scale-125 hover:-rotate-6"></img>
            </Link>
            <div className="flex justify-center items-center flex-col w-full fixed bottom-0">
                <AudioSpectrum
                    id="audio-canvas"
                    height={150}
                    width={windowWidth}
                    audioId={"audio-element"}
                    capColor={"#fdf709"}
                    capHeight={1.5}
                    meterWidth={14}
                    meterCount={256}
                    meterColor={[
                        { stop: 0, color: "#facc15" },
                        { stop: 0.2, color: "#facc15" },
                        { stop: 1, color: "#facc15" },
                    ]}
                    gap={7}
                />
                <div className="w-full font-omega bg-gradient-to-b from-yellow-400 to-orange-600 p-2 flex flex-col justify-center items tracking-widest font-bold text-3xl">
                    <audio loop ref={audioRef} className=" w-full h-full" id="audio-element" src={songList[songNumber]}></audio>

                    <div className="flex justify-center items-center flex-row gap-2">
                        <button onClick={togglePlaying}>{isPlaying ? <FaRegPauseCircle className="w-12 h-12 text-white" /> : <FaRegPlayCircle className="w-12 h-12 text-white" />}</button>
                        <p className="hidden  text-white text-3xl">{isPlaying ? "Pause" : "Playing"}</p>
                        <p className="text-xl md:text-3xl tracking-widest text-white">{songList[songNumber].slice(0, -4)}</p>
                        <FaRegArrowAltCircleLeft className="w-12 h-12 cursor-pointer text-white" onClick={handlePrevSong} />
                        <p className="text-2xl md:text-3xl text-white">
                            {songNumber + 1}/{songList.length}
                        </p>
                        <FaRegArrowAltCircleRight className="w-12 h-12 cursor-pointer text-white" onClick={handleNextSong} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
