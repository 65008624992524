"use client"
import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

const AudioSpectrum = ({ id, width, height, audioId, audioEle, capColor, capHeight, meterWidth, meterCount, meterColor, gap }) => {
   const canvasId = useRef(id || getRandomId(50))
   const audioContextRef = useRef(null)
   const audioEleRef = useRef()
   const audioCanvasRef = useRef()
   const playStatusRef = useRef()
   const mediaEleSourceRef = useRef()
   const analyserRef = useRef()
   const animationIdRef = useRef()

   useEffect(() => {
      prepareElements()
      initAudioEvents()
   }, [])

   const getRandomId = (len) => {
      let str = "1234567890-qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM"
      let strLen = str.length
      let res = ""
      for (let i = 0; i < len; i++) {
         let randomIndex = Math.floor(Math.random() * strLen)
         res += str[randomIndex]
      }
      return res
   }

   const prepareAPIs = () => {
      window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext
      window.requestAnimationFrame =
         window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame
      window.cancelAnimationFrame =
         window.cancelAnimationFrame || window.webkitCancelAnimationFrame || window.mozCancelAnimationFrame || window.msCancelAnimationFrame

      try {
         audioContextRef.current = new window.AudioContext()
      } catch (e) {
         console.log(e)
      }
   }

   const prepareElements = () => {
      if (!audioId && !audioEle) {
         console.log("target audio not found!")
         return
      }

      if (!audioId && !audioEle) {
         console.log("target audio not found!")
         return
      } else if (audioId) {
         audioEleRef.current = document.getElementById(audioId)
      } else {
         audioEleRef.current = audioEle
      }

      audioCanvasRef.current = document.getElementById(canvasId.current)
   }

   const setupAudioNode = (audioEle) => {
      if (!analyserRef.current) {
         analyserRef.current = audioContextRef.current.createAnalyser()
         analyserRef.current.smoothingTimeConstant = 0.8
         analyserRef.current.fftSize = 2048
      }

      if (!mediaEleSourceRef.current) {
         mediaEleSourceRef.current = audioContextRef.current.createMediaElementSource(audioEle)
         mediaEleSourceRef.current.connect(analyserRef.current)
         mediaEleSourceRef.current.connect(audioContextRef.current.destination)
      }

      return analyserRef.current
   }

   const drawSpectrum = (analyser) => {
      let cwidth = audioCanvasRef.current.width
      let cheight = audioCanvasRef.current.height - capHeight
      let capYPositionArray = [] // store the vertical position of hte caps for the preivous frame
      let ctx = audioCanvasRef.current.getContext("2d")
      let gradient = ctx.createLinearGradient(0, 0, 0, 300)

      if (meterColor.constructor === Array) {
         let stops = meterColor
         let len = stops.length
         for (let i = 0; i < len; i++) {
            gradient.addColorStop(stops[i]["stop"], stops[i]["color"])
         }
      } else if (typeof meterColor === "string") {
         gradient = meterColor
      }

      let drawMeter = () => {
         let array = new Uint8Array(analyser.frequencyBinCount) // item value of array: 0 - 255
         analyser.getByteFrequencyData(array)
         if (playStatusRef.current === "PAUSED") {
            for (let i = array.length - 1; i >= 0; i--) {
               array[i] = 0
            }
            let allCapsReachBottom = !capYPositionArray.some((cap) => cap > 0)
            if (allCapsReachBottom) {
               ctx.clearRect(0, 0, cwidth, cheight + capHeight)
               cancelAnimationFrame(audioContextRef.current) // since the sound is top and animation finished, stop the requestAnimation to prevent potential memory leak,THIS IS VERY IMPORTANT!
               return
            }
         }
         let step = Math.round(array.length / meterCount) // sample limited data from the total array
         ctx.clearRect(0, 0, cwidth, cheight + capHeight)
         for (let i = 0; i < meterCount; i++) {
            let value = array[i * step]
            if (capYPositionArray.length < Math.round(meterCount)) {
               capYPositionArray.push(value)
            }
            ctx.fillStyle = capColor
            // draw the cap, with transition effect
            if (value < capYPositionArray[i]) {
               // let y = cheight - (--capYPositionArray[i])
               let preValue = --capYPositionArray[i]
               let y = ((270 - preValue) * cheight) / 270
               ctx.fillRect(i * (meterWidth + gap), y, meterWidth, capHeight)
            } else {
               // let y = cheight - value
               let y = ((270 - value) * cheight) / 270
               ctx.fillRect(i * (meterWidth + gap), y, meterWidth, capHeight)
               capYPositionArray[i] = value
            }
            ctx.fillStyle = gradient // set the filllStyle to gradient for a better look

            // let y = cheight - value + this.props.capHeight
            let y = ((270 - value) * cheight) / 270 + capHeight
            ctx.fillRect(i * (meterWidth + gap), y, meterWidth, cheight) // the meter
         }
         animationIdRef.current = requestAnimationFrame(drawMeter)
      }
      animationIdRef.current = requestAnimationFrame(drawMeter)
   }

   const initAudioEvents = () => {
      let audioEle = audioEleRef.current
      if (audioEle) {
         audioEle.onpause = (e) => {
            playStatusRef.current = "PAUSED"
         }
         audioEle.onplay = (e) => {
            playStatusRef.current = "PLAYING"
            prepareAPIs()
            let analyser = setupAudioNode(audioEleRef.current)
            drawSpectrum(analyser)
         }
      }
   }

   return <canvas id={canvasId.current} width={width} height={height}></canvas>
}

AudioSpectrum.propTypes = {
   id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
   width: PropTypes.number,
   height: PropTypes.number,
   audioId: PropTypes.string,
   audioEle: PropTypes.object,
   capColor: PropTypes.string,
   capHeight: PropTypes.number,
   meterWidth: PropTypes.number,
   meterCount: PropTypes.number,
   meterColor: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
         PropTypes.shape({
            stop: PropTypes.number,
            color: PropTypes.string,
         })
      ),
   ]),
   gap: PropTypes.number,
}

/* AudioSpectrum.defaultProps = {
   width: 300,
   height: 200,
   capColor: "#FFF",
   capHeight: 2,
   meterWidth: 2,
   meterCount: 40 * (2 + 2),
   meterColor: [
      { stop: 0, color: "#f00" },
      { stop: 0.5, color: "#0CD7FD" },
      { stop: 1, color: "red" },
   ],
   gap: 10, // gap between meters
} */

export default AudioSpectrum
