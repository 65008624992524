import { useEffect, useRef, useState } from "react"
import { EmulatorJS, defaultPathToData } from "react-emulatorjs"
import { Link } from "react-router-dom"
import Home from "./Home"
import { FaRegPauseCircle, FaRegPlayCircle, FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight } from "react-icons/fa"
import AudioSpectrum from "./AudioSpectrum"

function Game() {
    // GET INNER WIDTH OF BROWSER //
    const [windowWidth, setWindowWidth] = useState(0)
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }
        handleResize() // Set the initial window width
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])
    // MUSIC PLAYER LOGIC //
    const [songNumber, setSongNumber] = useState(0)
    const [isMuted, setIsMuted] = useState(true)
    const audioRef = useRef()
    const [isPlaying, setIsPlaying] = useState(false)
    const songList = ["Papa Roach - Blood Brothers.mp3", "Bad religion - you.mp3", "Dub Pistols - Cyclone.mp3", "Lagwagon - May 16.mp3", "Heavy Metal Winner - Consumed.mp3"]

    const handleNextSong = () => {
        if (songNumber + 1 < songList.length) {
            setSongNumber(songNumber + 1)
        } else {
            return
        }
        setIsPlaying(false)
        setTimeout(() => {
            setIsPlaying(true)
        }, 300)
    }
    const handlePrevSong = () => {
        if (songNumber === 0) {
            return
        } else {
            setSongNumber(songNumber - 1)
            setIsPlaying(false)
            setTimeout(() => {
                setIsPlaying(true)
            }, 300)
        }
    }

    useEffect(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.play()
            } else {
                audioRef.current.pause()
            }
        }
    }, [isPlaying])

    const togglePlaying = () => {
        setIsPlaying(!isPlaying)
    }
    useEffect(() => {
        console.log(isPlaying)
    }, [isPlaying])
    // MUSIC PLAYER LOGIC ENDS HERE //
    return (
        <div className="flex w-full items-center h-full justify-center">
            <Link to="/">
                <img alt="" src="/home.webp" className="h-8 md:h-14 z-50 cursor-pointer absolute top-2 left-2 opacity-100 md:opacity-80 hover:opacity-100"></img>
            </Link>
            <div className="flex w-fit h-fit flex-1 justify-center items-center z-40">
                <EmulatorJS
                    EJS_core="pcsx_rearmed"
                    EJS_gameUrl="https://s3.us-east-1.wasabisys.com/thps2/thps.7z"
                    EJS_pathtodata="https://cdn.emulatorjs.org/4.0.8/data"
                    EJS_biosUrl="/scph5501.bin"
                    EJS_startOnLoaded
                />
            </div>
            <div className="flex justify-center items-center flex-col w-full absolute bottom-0">
                <AudioSpectrum
                    id="audio-canvas"
                    height={150}
                    width={windowWidth}
                    audioId={"audio-element"}
                    capColor={"#fdf709"}
                    capHeight={2}
                    meterWidth={14}
                    meterCount={256}
                    meterColor={[
                        { stop: 0, color: "#facc15" },
                        { stop: 0.2, color: "#facc15" },
                        { stop: 1, color: "#facc15" },
                    ]}
                    gap={7}
                />
                <div className="w-full font-omega bg-gradient-to-b from-yellow-400 to-orange-600 p-2 flex flex-row justify-center items tracking-widest font-bold text-3xl">
                    <audio loop ref={audioRef} className=" w-full h-full" id="audio-element" src={songList[songNumber]}></audio>

                    <div className="flex justify-center items-center flex-row gap-2">
                        <button onClick={togglePlaying}>{isPlaying ? <FaRegPauseCircle className="w-12 h-12 text-white" /> : <FaRegPlayCircle className="w-12 h-12 text-white" />}</button>
                        <p className="hidden  text-white text-3xl">{isPlaying ? "Pause" : "Playing"}</p>
                        <p className="text-xl md:text-3xl tracking-widest text-white">{songList[songNumber].slice(0, -4)}</p>
                        <FaRegArrowAltCircleLeft className="w-12 h-12 cursor-pointer text-white" onClick={handlePrevSong} />
                        <p className="text-2xl md:text-3xl text-white">
                            {songNumber + 1}/{songList.length}
                        </p>
                        <FaRegArrowAltCircleRight className="w-12 h-12 cursor-pointer text-white" onClick={handleNextSong} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Game
