import "./index.css"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import Game from "./Game"
import Home from "./Home"
import Tokenomics from "./Tokenomics"

function App() {
    return (
        <Router>
            <Routes>
                <Route index path="/" element={<Home />} />
                <Route path="/game" element={<Game />} />
                <Route path="/tokenomics" element={<Tokenomics />} />
            </Routes>
        </Router>
    )
}

export default App
