import React, { useState } from "react"
import copy from "clipboard-copy"
import { Link } from "react-router-dom"

function Tokenomics() {
    const [textToCopy, setTextToCopy] = useState("EBrMAYymNrAkgmmEkeZ23dhKe6oAT5ZwMXPPMEFDSDUp")
    const [isCopied, setIsCopied] = useState(false)

    const handleCopyClick = async () => {
        try {
            await copy(textToCopy)
            setIsCopied(true)
        } catch (err) {
            console.error("Failed to copy: ", err)
        }
    }

    return (
        <div className="flex w-full items-start h-full justify-center pt-28">
            <Link to="/">
                <img alt="" src="/home.webp" className=" h-8 md:h-14 cursor-pointer absolute top-2 left-2 opacity-100 md:opacity-80 hover:opacity-100"></img>
            </Link>
            <div className="flex justify-center flex-col w-full items-center space-y-3">
                <img className="w-full max-w-full md:max-w-lg" src="/thps.webp"></img>
                <p className="text-5xl font-omega text-white ">
                    Total Supply:
                    <br />
                    1,000,000,000
                </p>
                <p className="text-5xl font-omega text-white">
                    Symbol:
                    <br />
                    $THPS2
                </p>
                <button className="text-4xl p-5 px-8 rounded-2xl font-omega text-white flex justify-center items-center" onClick={handleCopyClick}>
                    {isCopied ? <img alt="" src="/copied.webp" className=" w-3/5 md:w-2/3 cursor-pointer"></img> : <img alt="" src="/copy.webp" className=" w-3/5 md:w-2/3 cursor-pointer"></img>}
                </button>
            </div>
        </div>
    )
}

export default Tokenomics
